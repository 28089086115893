#service-location .title {
    margin-top: 48px;
    font-weight: bold;
    margin-bottom: 12px;
}

#service-location .input-search-container {
    margin-top: 26px;
    margin-bottom: 0;
}

#service-location .autocomplete-dropdown-container {
    display: none;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px var(--white-two);
    background-color: var(--white);
}
#service-location .autocomplete-dropdown-container.ready {
    display: block;
}
#service-location .autocomplete-dropdown-container .suggestion-item {
    font-size: 14px;
    line-height: 1.57;
    color: #444;
    padding: 8px 16px;
}
#service-location .autocomplete-dropdown-container .suggestion-item:hover,
#service-location .autocomplete-dropdown-container .suggestion-item.active {
    cursor: pointer;
    background-color: #f2f2f2;
}

#service-location .maps {
    position: fixed;
    top: 113px;
    z-index: 1001;
    width: calc(100% - 55%);
    height: calc(100vh - 113px);
    right: 0;
    box-shadow: 4px 0 8px 0 rgba(0, 0, 0, 0.05);
}
#service-location .maps-marker {
    display: inline-block;
    transform: translate(-50%, -50%);
}

/*
responsive
*/
@media (max-width: 992px) {
    #service-location .maps {
        position: relative;
        width: 100%;
        height: 320px;
        top: 16px;
        margin-bottom: 48px;
    }
}
@media (max-width: 767px) {
    #service-location .title {
        font-size: 22px;
        margin-top: 24px;
    }
    .bottom-controls {
        z-index: 1002;
    }
}
